const REACTIVE_STRING_PREFIX = '_nuxt_reactive_storage_'

/**
 * Create a reactive variable that is synced to local storage using the
 * given key. The sync only happens client side and after the component
 * has been mounted.
 *
 * This composable can be used to keep state across page navigations and
 * even after a refresh.
 */
export function useLocalStorage<T>(
  context: string,
  key: string,
  defaultValue: T,
): Ref<T> {
  const variable = ref<T>(defaultValue)

  if (process.client) {
    const storageKey = REACTIVE_STRING_PREFIX + context + ':' + key
    const init = localStorage.getItem(storageKey)

    // Has to happen on mounted, else there will be hydration errors.
    onMounted(() => {
      if (init && typeof init === 'string') {
        try {
          const parsed = JSON.parse(init)
          if (parsed && typeof parsed === typeof defaultValue) {
            variable.value = parsed
          }
        } catch (_e) {}
      }
    })

    let timeout: number | undefined

    // Add a watcher for the ref. Whenever it changes we update the value
    // in local storage.
    watch(
      () => variable.value,
      (value) => {
        clearTimeout(timeout)
        if (value !== init) {
          timeout = window.setTimeout(() => {
            localStorage.setItem(storageKey, JSON.stringify(value))
          }, 300)
        }
      },
      { deep: true },
    )
  }

  return variable as Ref<T>
}

/**
 * Remove the items for the given context from local storage.
 */
export function clearReactiveStorage(context: string) {
  // Local storage is only available on client.
  if (process.client) {
    // The prefix we want to look up.
    const searchPrefix = REACTIVE_STRING_PREFIX + context

    Object.keys(localStorage).forEach((key) => {
      // Delete item from local storage if its matches our search prefix.
      if (key.startsWith(searchPrefix)) {
        localStorage.removeItem(key)
      }
    })
  }
}
