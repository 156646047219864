<template>
  <div class="flex gap-4 uppercase">
    <div v-for="item in items" :key="item.code">
      <NuxtLink
        v-if="item.path"
        :to="item.path"
        class="rounded-full px-8 pb-4 pt-6 text-sm font-bold leading-none"
        :class="{ 'bg-blue-dark-01 text-white': item.active }"
        :title="item.label"
        >{{ item.code }}</NuxtLink
      >
      <span
        v-else
        class="cursor-not-allowed px-4 text-sm opacity-30"
        :title="item.label"
        >{{ item.code }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
const languageLinks = await usePageLanguageLinks()
const currentLanguage = useCurrentLanguage()
const data = await useLocaleData()

const items = computed(() => {
  return data.value.languages.map((language) => {
    const link = languageLinks.value.find((v) => v.code === language.id)
    return {
      path: link?.path,
      code: language.id,
      label: language.name,
      active: currentLanguage.value === language.id,
    }
  })
})
</script>
